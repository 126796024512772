<template>
  <div>
    <div class="view pa24 mr mb20" style="display: flex;justify-content: space-between">
      <el-button type="info" class="ma ml10" @click="back">返回上一页</el-button>
      <el-button type="" class="ma ml10" @click="perfectInfo">完善信息</el-button>
    </div>
    <el-form
        ref="form" :model="form" :rules="rules" label-width="auto" class="view pa24" label-position="left"
    >
      <p class="cA2 fwbold fs8 mb20">会员基本信息</p>
      <div style="display: flex">
        <el-form-item prop="membershiptypeId" label="入会类型" style="width: 100%">
          <el-select v-model="form.membershiptypeId" clearable placeholder="请选择入会类型" style="width: 100%">
            <template>
              <el-option
                  v-for="item in membershiptypeType"
                  :label="item.name"
                  :value="item.index"
                  :key="item.index"
              />
            </template>
          </el-select>
        </el-form-item>
        <el-form-item prop="memberlevel" label="入会级别" style="width: 100%;margin: 0 20px">
          <el-select v-model="form.memberlevel" clearable placeholder="请选择入会级别" style="width: 100%">
            <template>
              <el-option
                  v-for="item in memberlevelList"
                  :label="item.levelName"
                  :value="item.memberLevelId"
                  :key="item.memberLevelId"
              />
            </template>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 100%">

        </el-form-item>
      </div>
      <hr v-if="form.membershiptypeId==0"
          style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <div style="display: flex" v-if="form.membershiptypeId==0">
        <el-form-item prop="enterpriseName" label="企业名称" style="width: 100%">
          <el-input
              clearable
              v-model="form.enterpriseName"
              placeholder="请输入企业名称"
              maxlength="100"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="registeredAddress" label="注册地址" style="width: 100%;margin: 0 20px">
          <el-input
              clearable
              v-model="form.registeredAddress"
              placeholder="请输入注册地址"
              maxlength="200"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="officeAddress" label="办公地址" style="width: 100%">
          <el-input
              clearable
              v-model="form.officeAddress"
              placeholder="请输入办公地址"
              maxlength="200"
              show-word-limit
          ></el-input>
        </el-form-item>
      </div>
      <div style="display: flex" v-if="form.membershiptypeId==0">
        <el-form-item prop="setUpTime" label="成立时间" style="width: 100%">
          <el-date-picker
              clearable
              value-format="yyyy-MM-dd"
              style="width: 100%"
              v-model="form.setUpTime"
              type="date"
              placeholder="成立时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="workerNum" label="职工人数" style="width: 100%;margin: 0 20px">
          <el-input
              clearable
              v-model="form.workerNum"
              placeholder="请输入职工人数"
              maxlength="10"
              show-word-limit
              min="0" @input="form.workerNum=form.workerNum.replace(/^0(0+)|[^\d]+/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属行业" prop="industryId" style="width: 100%">
          <el-select
              clearable
              v-model="form.industryId"
              style="width:100%"
              @change="toChoose"
          >
            <el-option
                v-for="(item) in industryList"
                :key="item.industryId"
                :label="item.industryName"
                :value="item.industryId"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item prop="desc" label="经营范围" style="width: 100%" v-if="form.membershiptypeId==0">
        <el-input clearable type="textarea" v-model="form.desc" maxlength="500" show-word-limit></el-input>
      </el-form-item>
      <hr
          style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <div style="display: flex">
        <el-form-item prop="name" label="姓　　名" style="width: 100%">
          <el-input
              clearable
              v-model="form.name"
              placeholder="请输入姓名"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="sex" label="性　　别" style="width: 100%;margin: 0 20px">
          <el-select
              clearable
              style="width: 100%"
              v-model="form.sex"
              placeholder="请选择性别"
          >
            <el-option
                v-for="(item) in sexList"
                :key="item"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="date" label="出生年月" style="width: 100%">
          <el-date-picker
              clearable
              value-format="yyyy-MM-dd"
              style="width: 100%"
              v-model="form.date"
              type="date"
              placeholder="出生年月">
          </el-date-picker>
        </el-form-item>
      </div>
      <el-form-item prop="coverImg" label="头　　像">
        <p class="ma mb10" style="color: rgb(150, 150, 150)">
          图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过500K
        </p>
        <div>
          <el-upload :action="uploadUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :before-upload="beforeAvatarUploadImg"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </div>
      </el-form-item>

      <div style="display: flex">
        <el-form-item prop="national" label="民　　族" style="width: 100%">
          <el-input
              clearable
              v-model="form.national"
              placeholder="请输入民族"
              maxlength="20"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="籍　　贯" prop="" style="width: 100%;margin: 0 20px">
          <el-cascader :placeholder="form.nativePlace"  :options="citys" style="width: 100%" filterable clearable
                       @change="handleChange">
            <template slot-scope="{ node, data }">
              <span>{{ data.label }}</span>
              <!--              <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>-->
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item prop="education" label="学　　历" style="width: 100%">
          <el-input
              clearable
              v-model="form.education"
              placeholder="请输入学历"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
      </div>
      <div style="display: flex">
        <el-form-item prop="politicalFM" label="政治风貌" style="width: 100%">
          <el-input
              clearable
              v-model="form.politicalFM"
              placeholder="请输入政治风貌"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="socialZW" label="社会职务" style="width: 100%;margin: 0 20px">
          <el-input
              clearable
              v-model="form.socialZW"
              placeholder="请输入社会职务"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="mobile" label="手机号码" style="width: 100%">
          <el-input
              clearable
              v-model="form.mobile"
              placeholder="请输入手机号码"
              maxlength="11"
              show-word-limit
          ></el-input>
        </el-form-item>
      </div>

      <hr
          style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />
      <div style="display: flex">
        <el-form-item prop="membershipDate" label="入会时间" style="width: 100%">
          <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 100%"
              v-model="form.membershipDate"
              type="date"
              placeholder="入会时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="membershipDateLength" label="入会时长" style="width: 100%;margin: 0 20px">
          <div style="display: flex">
            <el-input
                disabled
                clearable
                v-model="form.membershipDateLength"
                placeholder="入会时长"
                show-word-limit
            ></el-input>
            <span style="margin-left: 10px">天</span>
          </div>
        </el-form-item>
        <el-form-item style="width: 100%;">

        </el-form-item>
      </div>
      <hr
          style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 20px 0;
        "
      />

      <el-form-item label-width="0px">
        <el-button
            type="primary"
            size="medium"
            @click="add"
        >保存
        </el-button
        >
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import {upMember, queryMemberById} from "@/api/members";
import {fetchIndustryList, selectMemberFeeList} from '@/api/organization.js'
import {regionData, CodeToText} from 'element-china-area-data'

export default {
  data() {
    var valiCoverImg = (rule, value, callback) => { // 图片验证
      if (this.form.coverImg.length == 0) {
        callback();
      } else {
        callback();
      }
    };
    return {
      citys: regionData,
      membershiptypeType: [{index: 0, name: '企业'}, {index: 1, name: '个人'}],
      sexList: ['男', '女'],
      isShow: false,
      form: {
        membershiptypeId: '',
        enterpriseName: '',
        registeredAddress: '',
        officeAddress: '',
        setUpTime: '',
        workerNum: '',
        industryId: '',
        industryName: '',
        desc: '',
        name: '',
        sex: '',
        date: '',
        national: '',
        nativePlace: '请选择籍贯',
        education: '',
        politicalFM: '',
        socialZW: '',
        mobile: '',
        membershipDate: '',
        membershipDateLength: '',
        coverImg: '',
        memberlevel: '',
      },
      rules: {
        // membershiptypeId: [{required: true, message: "请选择入会类型！", trigger: "change"}],
        // coverImg: [{required: true, validator: valiCoverImg, trigger: "change"}],
      },
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      memberlevelList: [],
      industryList: [],
      fileList: [],
      province1: '',
      city1: '',
      county1: '',
      memberId: '',
    };

  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  async created() {
    this.queryPageMoney()
    this.getIndustryList()
    this.getDetails()
  },

  methods: {
    async queryPageMoney() {
      let data = {
        associationId: localStorage.getItem("associationId"),
        type:0,
      };
      try {
        const result = await selectMemberFeeList(data);
        this.memberlevelList = result.data;
      } catch (error) {

      }
    },
    getDetails() {
      let ajax = {
        memberId: this.$route.query.memberId,
        associationId: localStorage.getItem("associationId"),
      }
      queryMemberById(ajax)
          .then(res => {
            if (res.data) {
              let item = res.data
              // if (item.ifBoss == undefined){
              //   this.form.name = item.userName //姓名
              //   this.form.sex = item.gender==1?'男':'女' //性别
              //   this.form.mobile = item.tel //手机号码
              //   this.memberId = item.memberId
              // }else{
              this.form.membershiptypeId = item.ifBoss //入会类型
              this.form.memberlevel = item.level //入会级别
              this.form.enterpriseName = item.companyName //企业名称
              this.form.registeredAddress = item.registerAddress //注册地址
              this.form.officeAddress = item.officeAddress //办公地址
              this.form.setUpTime = item.establishTime //成立时间
              this.form.workerNum = item.workersCount //职工人数
              this.form.industryId = item.industryId //所属行业
              this.form.industryName = item.industry //所属行业
              this.form.desc = item.businessNature //经营范围
              this.form.name = item.userName //姓名
              this.form.sex = item.gender == 1 ? '男' : '女' //性别
              this.form.date = item.birthday //出生年月
              if (item.img) {
                this.fileList = [{url: item.img, uid: 1}]
              }

              this.form.national = item.nation //民族
              if (item.province) {
                this.form.nativePlace = item.province + item.city + item.county //籍贯
              }
              this.province1 = item.province
              this.city1 = item.city
              this.county1 = item.county
              this.form.education = item.education //学历
              this.form.politicalFM = item.politicalStyle //政治风貌
              this.form.socialZW = item.socialPosition //社会职务
              this.form.mobile = item.tel //手机号码
              if (item.joinTime&&!item.payDate) {
                this.form.membershipDate = this.renderTime(item.joinTime) //入会时间
                this.form.membershipDateLength = parseInt((new Date().getTime() - new Date(item.joinTime).getTime())/86400000)
              }else if (item.payDate&&!item.joinTime) {
                this.form.membershipDate = this.renderTime(item.payDate) //入会时间
                this.form.membershipDateLength = parseInt((new Date().getTime() - new Date(item.payDate).getTime())/86400000)
              }else if(item.payDate&&item.joinTime){
                this.form.membershipDate = this.renderTime(item.joinTime) //入会时间
                this.form.membershipDateLength = parseInt((new Date().getTime() - new Date(item.payDate).getTime())/86400000)
              }else {
                this.form.membershipDate = ''
                this.form.membershipDateLength = ''
              }
              this.memberId = item.memberId
              // }
            }
          })
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.form.coverImg = ''
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.form.coverImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    //新增商品
    add() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let data = {
            ifBoss: this.form.membershiptypeId, //入会类型
            level: this.form.memberlevel, //入会级别
            companyName: this.form.enterpriseName, //企业名称
            registerAddress: this.form.registeredAddress, //注册地址
            officeAddress: this.form.officeAddress, //办公地址
            establishTime: this.form.setUpTime, //成立时间
            workersCount: this.form.workerNum, //职工人数
            industryId: this.form.industryId, //所属行业
            industry: this.form.industryName, //所属行业
            businessNature: this.form.desc, //经营范围
            userName: this.form.name, //姓名
            // gender: this.form.sex, //性别
            birthday: this.form.date, //出生年月
            img: this.form.coverImg, //头像
            nation: this.form.national, //民族
            // nativePlace: this.form.nativePlace, //籍贯
            province: this.province1,
            city: this.city1,
            county: this.county1,
            education: this.form.education, //学历
            politicalStyle: this.form.politicalFM, //政治风貌
            socialPosition: this.form.socialZW, //社会职务
            tel: this.form.mobile, //手机号码
            joinTime: this.form.membershipDate, //入会时间
            // endDate: this.form.membershipDateLength, //入会时长
            memberId: this.memberId,
          };
          if (this.form.sex == '男') {
            data.gender = 1
          } else if (this.form.sex == '女') {
            data.gender = 2
          } else {
            data.gender = this.form.sex
          }
          // console.log(data)
          // return
          upMember(data)
              .then(() => {
                this.$message({
                  message: "保存成功",
                  type: "success",
                  center: true,
                });
                this.$router.go(-1);
              })
              .catch(err => {
                this.$message({
                  message: err.message,
                  type: "success",
                  center: true,
                });
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    back() {
      this.$router.go(-1)
    },
    perfectInfo() {
      let data = {
        memberId: this.memberId,
        ifSelf: 0
      }
      upMember(data)
          .then(() => {
            this.$message({
              message: "保存成功",
              type: "success",
              center: true,
            });
            this.$router.go(-1);
          })
          .catch(err => {
            this.$message({
              message: err.message,
              type: "success",
              center: true,
            });
          });
    },
    //获取行业列表
    getIndustryList() {
      fetchIndustryList()
          .then(res => {
            this.industryList = res.data
          })
          .catch(err => {
            console.log(err)
          })
    },
    toChoose() {
      for (let j in this.industryList) {
        if (this.form.industryId == this.industryList[j].industryId) {
          this.form.industryName = this.industryList[j].industryName
        }
      }
    },
    handleChange(value) {
      let cityNames = []
      value.forEach(e => {
        cityNames.push(CodeToText[e])
      });
      this.province1 = cityNames[0]
      this.city1 = cityNames[1]
      this.county1 = cityNames[2]
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500K!");
      }
      return isLt2M;
    },
  },
};
</script>
<style lang="scss" scope>
.disabled .el-upload--picture-card {
  display: none;
}
</style>

